export const VERSION = '6.0.23'
export const ACTIONS = {
    UPDATE: 'UPDATE',
    FORCE_UPDATE: 'FORCE_UPDATE',
    LOAD_JSON: 'LOAD_JSON',
    START_OVER: 'START_OVER',
    HISTORY: 'HISTORY',
    ZALESSKU: 'ZALESSKU',
    BESTSELLER: 'BESTSELLER'
  };
export const DEFAULT_STATE_VALUE = '0' //'-1'
export const ENGRAVING_TIMEOUT = 1000
export const INITIAL_STATE = {
  'version':VERSION,
  'showSticky':true,
  'active_view':1,
  'active_view4':1,
  'active_view5':1,
}
export const DEFAULT_ATTR_VALUE = {
 

  'band':'0',


}

//export const SPECIAL_NOTE_SMALL = ''
export const SPECIAL_NOTE_ACTIVATE = false
//export const SPECIAL_NOTE_SMALL ='Order by Wednesday, December 6, 2023 for Christmas delivery. This delivery date overrides regular processing times.'

//export const SPECIAL_NOTE_SMALL = 'Order by Tuesday, December 10, 2024 for Christmas delivery. This delivery date overrides regular processing times. '
export const SPECIAL_NOTE_SMALL = "Order by Monday, January 20, 2025 for Valentine's Day delivery. This delivery date overrides regular processing times. "
//export const SPECIAL_NOTE_SMALL = "Order by Friday, April 26, 2024 for Mother's Day delivery. This delivery date overrides regular processing times. "
//export const SPECIAL_NOTE_SMALL_PPL = "Order by Friday, January 26, 2024 for Valentine's Day delivery. This delivery date overrides regular processing times."
//export const SPECIAL_NOTE_SMALL_PPL = "Order by Sunday, April 21, 2024 for Mother's Day delivery. This delivery date overrides regular processing times. "
//export const SPECIAL_NOTE_SMALL_PPL = 'Order by Monday, November 25, 2024 for Christmas delivery. This delivery date overrides regular processing times. '
export const SPECIAL_NOTE_SMALL_PPL = "Order by Monday, January 20, 2025 for Valentine's Day delivery. This delivery date overrides regular processing times. "

//export const SPECIAL_NOTE_SMALL1 = 'Need a gift by Christmas,  '
//export const SPECIAL_NOTE_LINK =  "//www.kay.com/-lastmingifts/c/9000001002?q=*%3Afeatures%3AjewelrySearchType_string%3ARing%3AringStyle_string%3AEngagement+Ring%3AringStyle_string%3ABridal+Set%3AringStyle_string%3AWedding&text=&searchStore=&loadMore=4"
//export const SPECIAL_NOTE_SMALL2 = ' to see engagement rings available for Christmas Delivery.'
export const SPECIAL_NOTE_BIG = SPECIAL_NOTE_SMALL
export const SPECIAL_NOTE_BIG_PPL = SPECIAL_NOTE_SMALL_PPL
//export const SPECIAL_NOTE_BIG = 'Order by Tuesday, December 7, 2021 for Christmas delivery. This delivery date overrides regular processing times noted below.'
//export const SPECIAL_NOTE_BIG = "Order by Wednesday, January 25, 2023 for Valentine's Day delivery. This delivery date overrides regular processing times. "
//export const SPECIAL_NOTE_BIG = "Order by Thursday, April 14, 2022 for Mother's Day delivery. This delivery date overrides regular processing times. "
//export const SPECIAL_NOTE_BIG = 'Order by Tuesday, December 6, 2022 for Christmas delivery. This delivery date overrides regular processing times. '

export const MSG_NO_SESSION1 = 'Site error. Please return to '
export const MSG_NO_SESSION2 = ', personalize, and add to cart again. If problem persists, please contact customer service. We’re sorry for the inconvenience.'
export const MSG_NOT_READY_ADD_TO_CART ='Please select RING SIZE in order to add product to the shopping bag'
export const MSG_NO_USER_DIAMOND ='Please provide all correct information for Customer Diamond'
export const PRICE_URL = 'https://test.thepersonalizedbest.com/b2_getprice/'
//export const PRICE_URL = 'https://main.thepersonalizedbest.com/b23_getprice/'
export const PRICE_URL11 = 'http://52.21.230.12/b23_getprice/'
export const PRICE_URL_PROD = '//main.thepersonalizedbest.com/b23_getprice/'
export const CFG_URL = 'https://test.thepersonalizedbest.com/b2_cfg2sku7/' //s://main-master.thepersonalizedbest.com/b2_add_to_cart7' 
export const ADD_TO_CART_URL_PROD = 'https://main.thepersonalizedbest.com/b2_add_to_cart23' //bridal_cfg2sku6/'
export const CFG_URL_PROD = '//main.thepersonalizedbest.com/b2_cfg2sku7/' //bridal_cfg2sku6/'
//export const ADD_TO_CART_URL = 'https://main.thepersonalizedbest.com/b2_add_to_cart23' //bridal_add_to_cart6'
export const ADD_TO_CART_URL = 'https://test.thepersonalizedbest.com/b2_add_to_cart7' //bridal_add_to_cart6'
//export const ADD_TO_CART_URL = 'httpttps://main.thepersonalizedbest.com/b2_add_to_cart7' //bridal_add_to_cart6'
export const OPTION_URL = '//static.thepersonalizedbest.com/bridal/'
export const DYNAMIC_OPTION_URL6 = '//test.thepersonalizedbest.com/bridal_image5a/'
export const ENGRAVING_URL = '//bridalimages.thepersonalizedbest.com/text.jpg'  //  t ,f
//export const ENGRAVING_URL = '//bridalimages-master.thepersonalizedbest.com/text.jpg'  //  t ,f
//export const DYNAMIC_BAND_OPTION_URL ='//bridalimages.thepersonalizedbest.com/bridal_image/'
//export const DYNAMIC_BAND_OPTION_URL ='//test.thepersonalizedbest.com/bridal_image5/'
export const DEFAULT_CFG = 'CFG12559000000000'
export const DEFAULT_K_SKU = '2DSZVHIT1YS0A' //'2DSZVHIT1YS0H7Z'
export const DEFAULT_J_SKU = '2MYMN0A'       //'2MYMN0BUTA'
export const DEFAULT_Z_SKU = '2HCQHOQOEYZ0A' //'2HCQHOQOEYZ0H7Z'
export const DEFAULT_KO_SKU = '2VHNWIOUDPI0A'
export const DEFAULT_ZO_SKU = '2Y1EIPWOQPN0A'
export const DEFAULT_PPL_SKU = '23J44W5VS2S0A'

export const DEFAULT_Z_QSKU = '20155466'
export const DEFAULT_ZO_QSKU = '20563139'
export const DEFAULT_K_QSKU = 'bridalqsku'
export const DEFAULT_K_QSKU2 = 'bridalsetqsku'
export const DEFAULT_KO_QSKU = 'bridalqsku'

export const DEFAULT_J_QSKU = 'bridalqsku'
export const DEFAULT_J_QSKU2 = 'bridalsetqsku'

export const DEFAULT_K_URL = 'https://www.kay.com/pjconfigurator/bridal'
export const DEFAULT_J_URL = 'https://www.jared.com/pjconfigurator/bridal'
export const DEFAULT_Z_URL = 'https://www.zales.com/pjconfigurator/bridal'
export const DEFAULT_KO_URL = 'https://www.kayoutlet.com/pjconfigurator/bridal'
export const DEFAULT_PPL_URL = 'https://www.peoplesjewellers.com/pjconfigurator/bridal'
export const DEFAULT_ZO_URL = 'https://www.zalesoutlet.com/pjconfigurator/bridal'

export const INITIAL_ZALES_IMG_URL ='//static.thepersonalizedbest.com/bridal/init_zales.jpg'
export const INITIAL_KAY_IMG_URL ='//static.thepersonalizedbest.com/bridal/init_kay.jpg'
export const INITIAL_KAY_IMG_URL2 ='//static.thepersonalizedbest.com/bridal/init_kay2.jpg'
export const ZALES_SKU_URL ='//test.thepersonalizedbest.com/b2_zsku2sku7/?zsku=' //bridal_zsku2sku6/?zsku='
export const ZALES_SKU_URL_PROD ='//main.thepersonalizedbest.com/b2_zsku2sku7/?zsku=' //bridal_zsku2sku6/?zsku='

export const START_OVER_STATE = {
  'ud':'D',
  'active_view':1,
 
  rs:  DEFAULT_STATE_VALUE,
  er:  "",
  eb1:  "",

}
